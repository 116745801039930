export const required = value =>
  value || typeof value === "number" ? undefined : "Field is required";
export const validEmail = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? "Email is invalid"
    : undefined;

export const validWebsite = value =>
  value &&
  !/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/i.test(
    value
  )
    ? "Website is invalid"
    : undefined;
