import styled from "styled-components";
import { Box, Flex } from "rebass";

export const ModalContainer = styled(Box)`
  position: fixed; /* Stay in place */
  z-index: 500; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

export const ModalContent = styled(Box)`
  background: #f7f8f9;
  border: 1px solid #eaebeb;
  border-radius: 8px;
  max-width: 550px;
  min-height: 200px;
  margin: 10% auto;

  padding: 15px 20px 15px 20px;
`;

export const ModalHeader = styled(Flex)`
  font-size: 16px;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
`;


export const modalStyles = (maxWidth = 550) => {
  return {
    // dark background behind all modals
    overlay: {
      background: "rgba(0,0,0,0.25)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //overflowY: 'visible',
      overflowX: "hidden",
      zIndex: 101,
      padding: "15px 20px 15px 20px"
    },
    // modal root
    content: {
      overflow: "auto",
      maxHeight: "95vh",
      background: "#F7F8F9",
      backgroundClip: "padding-box",
      borderRadius: "16px",
      border: "1px",
      borderColor: "#EAEBEB",
      width: "100%",
      maxWidth: `${maxWidth}`,
      top: "auto",
      bottom: "auto",
      left: "auto",
      right: "auto",
      backgroundColor: "white",
      boxShadow:
        "0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)",
      padding: "20px"
    }
  };
};
