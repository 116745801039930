import React from "react";
import { connect } from "react-redux";
import TagListModal from "../content/vetted-search/TagListModal";
import ArticleContentModal from "../content/vetted-search/ArticleContentModal";
import ArticleDebugModal from "../content/vetted-search/ArticleDebugModal";
import SearchCheatSheetModal from "../content/vetted-search/SearchCheatSheetModal";
import EditTopicModal from "../topics/EditTopicModal";

const MODAL_COMPONENTS = {
  TAG_LIST_MODAL: TagListModal,
  ARTICLE_CONTENT_MODAL: ArticleContentModal,
  ARTICLE_DEBUG_MODAL: ArticleDebugModal,
  SEARCH_CHEAT_SHEET_MODAL: SearchCheatSheetModal,
  EDIT_TOPIC_MODAL: EditTopicModal
};

/*
  Takes a modalType and modalProps to dynamically return the
  modal component we imported above
*/
const modalRoot = ({ modalType, modalProps }) => {
  if (!modalType) {
    return null;
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} />;
};

const mapStateToProps = state => ({
  modalProps: state.modals.modalProps,
  modalType: state.modals.modalType
});

export default connect(mapStateToProps)(modalRoot);
