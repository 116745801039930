import React from "react";

import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";

interface IHeaderMenuItemProps extends RouteComponentProps {
  title: string;
  to: string;
  iconClass: string;
  exact: boolean;
}

const HeaderMenuItem = ({
  title,
  to,
  iconClass,
  exact,
  location
}: IHeaderMenuItemProps) => {
  let active = false;

  if (exact && location.pathname === to) {
    active = true;
  } else if (exact !== true && location.pathname.startsWith(to)) {
    active = true;
  }

  return (
    <li
      className={`m-menu__item ${active ? "m-menu__item--active" : ""}`}
      aria-haspopup="true"
    >
      <Link to={to} className="m-menu__link ">
        <i className={`m-menu__link-icon ${iconClass}`} />
        <span className="m-menu__link-text">{title}</span>
      </Link>
    </li>
  );
};

export default withRouter(HeaderMenuItem);
