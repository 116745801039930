import { all, fork } from "redux-saga/effects";

import authSaga from "./AuthSaga";
import accountSaga from "./AccountSaga";
import postSaga from "./PostSaga";
import scheduledPostSaga from "./ScheduledPostSaga";
import suggestedPostSaga from "./SuggestedPostSaga";

export default function* root() {
  yield all([
    fork(authSaga),
    fork(accountSaga),
    fork(postSaga),
    fork(scheduledPostSaga),
    fork(suggestedPostSaga)
  ]);
}
