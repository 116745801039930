import Dropzone from "react-dropzone";
import { DropZoneContainer, LoadingBox } from "./styles";
import { Plus } from "styled-icons/feather";

import "react-sweet-progress/lib/style.css";
import { Progress } from "react-sweet-progress";

import ReactLoading from "react-loading";
import React from "react";

export default function UploadZone({
  isUploading,
  progress,
  accept = "image/*",
  onDrop,
  type
}) {
  return (
    <Dropzone accept={accept} onDrop={onDrop}>
      {({ getRootProps, getInputProps, isDragActive }) => {
        return (
          <DropZoneContainer
            {...getRootProps()}
            isDragActive={isDragActive}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            type={type}
          >
            <input {...getInputProps()} />

            {!isUploading && (
              <>
                <Plus color="#D0D6DA" size={30} />
              </>
            )}

            {isUploading && progress !== 100 && (
              <Progress
                type="circle"
                strokeWidth={3}
                width={72}
                theme={{
                  default: {
                    trailColor: "#D0D6DA",
                    color: "#33ADFF"
                  }
                }}
                percent={progress}
              />
            )}

            {isUploading && progress === 100 && (
              <LoadingBox>
                <ReactLoading color="#999" type="spin" width={20} height={40} />
                <span>Processing, please wait...</span>
              </LoadingBox>
            )}
          </DropZoneContainer>
        );
      }}
    </Dropzone>
  );
}
