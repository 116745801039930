import styled from "styled-components";
import { Box, Flex } from "rebass";

export const IconButton = styled(Box)`
  cursor: pointer;
`;

export const Notifications = styled(Box)``;

export const Notification = styled(Flex)`
  padding: 5px;
  border-bottom: 1px solid #eee;
  justify-content: space-between;
`;

export const NotificationText = styled(Box)`
  & > em {
    color: black;
    font-style: normal;
  }
`;

export const NotificationTime = styled(Box)`
  color: #999;
`;
