import React from "react";

import logo from "./../assets/images/willow-logo-medium.png";
import { Link } from "react-router-dom";

const Aside = ({ visible }) => {
  return (
    <div
      id="m_aside_left"
      className="m-grid__item	m-aside-left  m-aside-left--skin-light"
      style={{ visibility: visible ? "visible" : "hidden" }}
    >
      <div className="m-brand  m-brand--skin-light ">
        <a href="/" className="m-brand__logo">
          <img alt="Contento" src={logo} style={{ width: 60, height: 60 }} />
        </a>
      </div>
      <div
        id="m_ver_menu"
        className="m-aside-menu  m-aside-menu--skin-light m-aside-menu--submenu-skin-light "
        data-menu-vertical="true"
        m-menu-scrollable="true"
        m-menu-dropdown-timeout="500"
      >
        <ul className="m-menu__nav  m-menu__nav--dropdown-submenu-arrow ">
          <li
            className="m-menu__item  m-menu__item--submenu"
            aria-haspopup="true"
            m-menu-submenu-toggle="click"
            m-menu-link-redirect="1"
          >
            <a className="m-menu__link m-menu__toggle" href="#toggle">
              <i className="m-menu__link-icon flaticon-add" />
              <span className="m-menu__link-text">Add</span>
              <i className="m-menu__ver-arrow la la-angle-right" />
            </a>
            <div className="m-menu__submenu ">
              <span className="m-menu__arrow" />
              <ul className="m-menu__subnav">
                <li
                  className="m-menu__item  m-menu__item--parent"
                  aria-haspopup="true"
                  m-menu-link-redirect="1"
                >
                  <span className="m-menu__link">
                    <span className="m-menu__link-text">Add</span>
                  </span>
                </li>
                <li
                  className="m-menu__item "
                  aria-haspopup="true"
                  m-menu-link-redirect="1"
                >
                  <Link to="/accounts/new" className="m-menu__link ">
                    <i className="m-menu__link-icon la la-commenting" />
                    <span className="m-menu__link-text">Account</span>
                  </Link>
                </li>
                <li
                  className="m-menu__item "
                  aria-haspopup="true"
                  m-menu-link-redirect="1"
                >
                  <Link to="/users/new" className="m-menu__link ">
                    <i className="m-menu__link-icon la la-user" />
                    <span className="m-menu__link-text">User</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Aside;
