export const colors = {
  blue: "#33ADFF",
  orange: "#FFB145",
  black: "#1B1D1F",
  gray: "#8B93A6",
  red: "#D0154A",
  white: "#ffffff",
  facebookBlue: "#3B5998",
  twitterBlue: "#0084b4",
  linkedInBlue: "#0077B5",
  instagramRed: "#e4405f"
};

export const iconStyles = {
  FACEBOOK: {
    color: colors.facebookBlue,
    iconClass: "socicon-facebook"
  },
  INSTAGRAM: {
    color: colors.instagramRed,
    iconClass: "socicon-instagram"
  },
  LINKEDIN: {
    color: colors.linkedInBlue,
    iconClass: "socicon-linkedin"
  },
  TWITTER: {
    color: colors.twitterBlue,
    iconClass: "socicon-twitter"
  }
};

export const theme = {
  colors: {
    primary: colors.blue,
    text: colors.black,
    background: "#F2F2F2",
    ...colors
  },
  breakpoints: ["850px", "1100px", "64em"],
  font: "Nunito Sans, sans-serif"
};
