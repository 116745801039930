import React, { useState } from "react";
import { callApi } from "../../utils/ContentoApi";
import UploadZone from "./UploadZone";
import { toastr } from "react-redux-toastr";

const ImageUpload = ({ onUpload, onUploadStart, onChange }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const onDrop = images => {
    setIsUploading(true);
    if (onUploadStart) {
      onUploadStart();
    }

    const uploads = images.map(image => {
      const formData = new FormData();
      formData.append("file", image);
      formData.append("timestamp", (Date.now() / 1000) | 0);
      return callApi({
        method: "post",
        url: "images/upload",
        data: formData,
        headers: [{ "Content-Type": "multipart/form-data" }],
        onUploadProgress: function(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        }
      });
    });

    Promise.all(uploads)
      .then(imageUploadResults => {
        const allImages = [].concat.apply([], imageUploadResults);
        setIsUploading(false);

        if (onUpload) {
          onUpload(allImages);
        }

        if (onChange) {
          onChange(allImages[0].path);
        }
      })
      .catch(err => {
        console.log(err);
        toastr.error(
          `There was an error uploading the picture. Please try again later or contact support.`
        );
        onUpload(false);
      });
  };

  return (
    <UploadZone
      accept="image/*"
      isUploading={isUploading}
      progress={progress}
      onDrop={onDrop}
    />
  );
};

export default ImageUpload;
