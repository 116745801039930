import axios from "axios";

import config from "./../config";
import authService from "./Auth";

async function callApi({ headers, ...rest }, loginRedirect = true) {
  try {
    const token = authService.getAccessToken();
    const options = {
      baseURL: config.apiUrl,
      headers: {
        ...headers
      },
      ...rest
    };

    if (token) {
      options.headers["authorization"] = `Bearer ${token}`;
    }

    const response = await axios(options);
    return response.data;
  } catch (error) {
    if (!error.response || !error.response.status) {
      let newError = new Error("Network error");
      newError.name = "NetworkError";
      throw newError;
    }

    if (error.response && error.response.data && error.response.data.message) {
      let newError = new Error(error.response.data.message);
      if (error.response.status === 400) {
        newError.name = "ValidationError";
      } else if (error.response.status === 401 && loginRedirect) {
        newError.name = "AuthorizationError";
        //Force reload. Ugly but practical.
        window.location.reload();
        return;
      }
      throw newError;
    } else {
      throw error;
    }
  }
}

export { callApi };
