import React from "react";
import { Link } from "react-router-dom";
import logo from "./../assets/images/logo-original-medium.png";
import { connect } from "react-redux";
import HeaderMenuItem from "../components/common/HeaderMenuItem";

class Header extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <header
        id="m_header"
        className="m-grid__item    m-header "
        m-minimize-offset="200"
        m-minimize-mobile-offset="200"
      >
        <div className="m-container m-container--fluid m-container--full-height">
          <div className="m-stack m-stack--ver m-stack--desktop">
            <div className="m-stack__item m-brand ">
              <div className="m-stack m-stack--ver m-stack--general">
                <div className="m-stack__item m-stack__item--middle m-brand__logo">
                  <Link to="/" className="m-brand__logo-wrapper">
                    <img width={60} alt="Contento" src={logo} />
                  </Link>
                </div>
                <div className="m-stack__item m-stack__item--middle m-brand__tools">
                  <a
                    id="m_aside_left_offcanvas_toggle"
                    href="#toggle"
                    className="m-brand__icon m-brand__toggler m-brand__toggler--left m--visible-tablet-and-mobile-inline-block"
                  >
                    <span />
                  </a>

                  <a
                    id="m_aside_header_menu_mobile_toggle"
                    href="#toggle"
                    className="m-brand__icon m-brand__toggler m--visible-tablet-and-mobile-inline-block"
                  >
                    <span />
                  </a>

                  <a
                    id="m_aside_header_topbar_mobile_toggle"
                    href="#toggle"
                    className="m-brand__icon m--visible-tablet-and-mobile-inline-block"
                  >
                    <i className="flaticon-more" />
                  </a>
                </div>
              </div>
            </div>
            <div
              className="m-stack__item m-stack__item--fluid m-header-head"
              id="m_header_nav"
            >
              <button
                className="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-light "
                id="m_aside_header_menu_mobile_close_btn"
              >
                <i className="la la-close" />
              </button>

              <div
                id="m_header_menu"
                className="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-light m-aside-header-menu-mobile--submenu-skin-light "
              >
                <ul className="m-menu__nav  m-menu__nav--submenu-arrow ">
                  <HeaderMenuItem
                    title="Dashboard"
                    to="/"
                    exact
                    iconClass="flaticon-map"
                  />
                  <HeaderMenuItem
                    title="Accounts"
                    to="/accounts"
                    iconClass="flaticon-profile-1"
                  />
                  <HeaderMenuItem
                    title="Users"
                    to="/users"
                    iconClass="flaticon-users"
                  />
                  <HeaderMenuItem
                    title="Operations"
                    to="/operations"
                    iconClass="flaticon-users"
                  />
                  <HeaderMenuItem
                    title="Content Search"
                    to="/content/vetted/search"
                    iconClass="flaticon-search"
                  />
                  <HeaderMenuItem
                    title="Source Management"
                    to="/sources"
                    iconClass="flaticon-book"
                  />
                </ul>
              </div>
              <div
                id="m_header_topbar"
                className="m-topbar  m-stack m-stack--ver m-stack--general"
              >
                <div className="m-stack__item m-topbar__nav-wrapper">
                  <ul className="m-topbar__nav m-nav m-nav--inline">
                    {user && (
                      <li
                        className="m-nav__item m-topbar__user-profile  m-dropdown m-dropdown--medium m-dropdown--arrow  m-dropdown--align-right m-dropdown--mobile-full-width m-dropdown--skin-light"
                        m-dropdown-toggle="click"
                      >
                        <a
                          className="m-nav__link m-dropdown__toggle"
                          href="#toggle"
                        >
                          <span className="m-topbar__userpic m--hide">
                            <img
                              src="assets/app/media/img/users/user4.jpg"
                              className="m--img-rounded m--marginless m--img-centered"
                              alt=""
                            />
                          </span>
                          <span className="m-nav__link-icon m-topbar__usericon">
                            <span className="m-nav__link-icon-wrapper">
                              <i className="flaticon-user-ok"></i>
                            </span>
                          </span>
                          <span className="m-topbar__username m--hide">
                            {user.given_name}
                          </span>
                        </a>
                        <div className="m-dropdown__wrapper">
                          <span className="m-dropdown__arrow m-dropdown__arrow--right m-dropdown__arrow--adjust"></span>
                          <div className="m-dropdown__inner">
                            <div className="m-dropdown__header m--align-center">
                              <div className="m-card-user m-card-user--skin-light">
                                <div className="m-card-user__pic">
                                  <img
                                    src={user.picture}
                                    className="m--img-rounded m--marginless"
                                    alt={user.name}
                                  />
                                </div>
                                <div className="m-card-user__details">
                                  <span className="m-card-user__name m--font-weight-500">
                                    {user.name}
                                  </span>
                                  <span className="m-card-user__email m--font-weight-300 m-link">
                                    {user.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="m-dropdown__body">
                              <div className="m-dropdown__content">
                                <ul className="m-nav m-nav--skin-light">
                                  <li className="m-nav__item">
                                    <Link
                                      to="/logout"
                                      className="btn m-btn--pill btn-secondary m-btn m-btn--custom m-btn--label-brand m-btn--bolder"
                                    >
                                      Logout
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {})(Header);
